<template>

  <template v-if="isLoading || !isTimeoutExpired">
    <div class="container center">
      <img class="satellite-image" src="@/assets/images/satellite.png" />
      <p class="pending-message">
        <template v-if="arePatchesPseudoPaused">
          We’re saving your Patch...
        </template>
        <template v-else>
          We’re turning your Patch on...
        </template>
      </p>
    </div>
  </template>

  <template v-else>
    <template v-if="submitErrors.length !== 0">
      <div class="container">
        <div class="header">
          <ErrorIcon />
          <h4>Oops!</h4>
        </div>
        <p>Something went wrong:</p>
        <ul>
          <li :key="index" v-for="(error, index) in submitErrors">
            {{ error }}
          </li>
        </ul>
        <p>Your Patch was not created. Please correct the error{{ submitErrors.length === 1 ? '' : 's' }} listed above and try again.</p>
        <button class="small go-back-button" @click="close">Go back</button>
      </div>
    </template>
    <template v-else>
      <div class="container center" v-if="arePatchesPseudoPaused">
        <img class="planet-image" src="@/assets/images/planet-2.png" />
        <h4>Patch saved!</h4>
        <p class="text-center">
          You can turn it on after
          <strong>{{ usageCycleEndsAt }}</strong>
          (when your usage cycle restarts).
          <br>
          <br>
          Upgrade to activate your Patches now.
        </p>
        <div class="button-container">
          <button class="small tertiary" @click="createAnother">Create another Patch</button>
          <router-link class="button small" to="/account/plans">View Plans</router-link>
        </div>
      </div>
      <div class="container center" v-else>
        <img class="planet-image" src="@/assets/images/planet-2.png" />
        <h4>Success!</h4>
        <p class="text-center">Your Patch is now active and monitoring for activity.</p>
        <div class="button-container">
          <button class="small tertiary" @click="createAnother">Create another Patch</button>
          <button class="small" @click="viewPatches">View Patches</button>
        </div>
      </div>
    </template>
  </template>

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import ErrorIcon from '@/assets/icons/error.svg'

  export default {
    components: {
      ErrorIcon,
    },
    props: {
      onSuccess: {
        type: Function,
        required: true,
      },
      onError: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapState('user', ['user', 'userPlan']),
      ...mapGetters('user', ['arePatchesPseudoPaused', 'usageCycleEndsAt']),

      form() {
        return this.$store.state.forms[this.formName]
      },
      isLoading() {
        return this.form.isLoading
      },
      submitErrors() {
        return this.form.submitErrors
      },
    },
    mounted() {
      // make sure to show the "saving" message for a few seconds so it's not an
      //  instant flash when the modal opens if the API is quick to respond
      setTimeout(() => {
        this.isTimeoutExpired = true
      }, 2000)

      return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
        .then((response) => {
          return this.onSuccess(response.data)
        })
        .catch((error) => {
          // do nothing else, template will show error
          return this.onError(error)
        })
    },
    data() {
      return {
        isTimeoutExpired: false,
        formName: 'createPatchForm',
      }
    },
    methods: {
      close() {
        this.$store.dispatch('modals/CLOSE_MODAL')
      },
      createAnother() {
        this.$router.replace({
          name: 'Redirect',
          params: {
            referrerLink: 'create-another',
            destinationRouteName: 'PatchCreate',
          },
        })
      },
      viewPatches() {
        this.$router.push('/patches/overview')
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .container
    @apply flex
    @apply flex-col

    &.center
      @apply items-center
      @apply justify-center

  .pending-message
    @apply m-0
    @apply text-xl
    @apply text-center
    @apply font-semibold

  .satellite-image
    @apply h-32
    @apply mb-8

  .planet-image
    @apply h-36
    @apply mb-4

  .header
    @apply mb-6
    @apply space-x-2

    @apply flex
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-danger-700

  ul
    @apply my-6

  .go-back-button
    @apply mt-6
    @apply self-end

  .gauge-container
    @apply w-full

    max-width: 20rem

  .button-container
    @apply mt-8

    @apply flex
    @apply flex-row
    @apply space-x-4

</style>
